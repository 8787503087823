import { CommonResponse, get } from './common.ts'

// GET /footer/text
interface GetFooterTextResponse {
  ceo: string
  cpo: string
  regNumber: string
  telNumber: string
  headOffice: string
  faxNumber: string
  researchInstitute: string
}

export const getFooterText = async (): Promise<CommonResponse<GetFooterTextResponse>> => {
  return await get('/footer/text')
}
