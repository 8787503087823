import Instagram from 'assets/logos/instagram.svg'
import Youtube from 'assets/logos/youtube.svg'
import Facebook from 'assets/logos/facebook.svg'
import Blog from 'assets/logos/blog.svg'
import Apple from 'assets/logos/apple.svg'
import Playstore from 'assets/logos/playstore.svg'

import { useEffect, useState } from 'react'

// api
import { getFooterText } from 'apis/footer.ts'

export default function Footer() {
  const [footerText, setFooterText] = useState({})

  const getFooterApi = async () => {
    const getFooterTextResponse = await getFooterText()

    if (getFooterTextResponse.error) {
      console.log(getFooterTextResponse)
      return
    }
    // console.log(getFooterTextResponse.data)
    setFooterText(getFooterTextResponse.data)
  }

  useEffect(() => {
    getFooterApi()
  }, [])

  return (
    <div className='bg-[#292A2D]'>
      <div className='container px-20 pt-40 pb-24 mx-auto'>
        <div className='flex flex-col gap-40 sm:flex-row'>
          <div className='w-[50px] mx-auto shrink-0'>
            <img src={require('assets/logos/logo-footer.png')} alt='에듀케이션랩' />
          </div>
          <div className='flex flex-col gap-32 grow'>
            <div className='flex flex-col gap-20'>
              <p className=' text-h1-semibold text-sk-assistive'>Education LAB Corp.</p>
              <div className='flex flex-col gap-10 text-body3-medium text-[#C2C4C8]'>
                <div className='flex flex-wrap gap-8 divide-x divide-sk-border-sub'>
                  <p>대표이사: {footerText.ceo}</p>
                  <p className='pl-8'>정보책임자: {footerText.cpo}</p>
                  <p className='pl-8'>사업자등록번호: {footerText.regNumber}</p>
                  <p className='pl-8'>대표번호: {footerText.telNumber}</p>
                </div>
                <div className='flex flex-wrap gap-8 divide-x divide-sk-border-sub'>
                  <p>본사: {footerText.headOffice}</p>
                  <p className='pl-8'>팩스번호: {footerText.faxNumber} </p>
                </div>
                <p>김포 연구소: {footerText.researchInstitute}</p>
              </div>
            </div>
            <div className='flex flex-wrap-reverse items-center justify-between w-full gap-20'>
              <p className='text-body3-medium text-sk-info'>Copyright©E-LAB All Rights reserved</p>
              <div className='flex items-center gap-16'>
                <a
                  href='https://section.blog.naver.com/BlogHome.nhn?directoryNo=0&currentPage=1&groupId=0'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={Blog} alt='blog' />
                </a>
                <a href='https://www.apple.com/' target='_blank' rel='noreferrer'>
                  <img src={Apple} alt='Apple' />
                </a>
                <a href='https://www.instagram.com/' target='_blank' rel='noreferrer'>
                  <img src={Instagram} alt='instagram' />
                </a>
                <a href='https://www.youtube.com/' target='_blank' rel='noreferrer'>
                  <img src={Youtube} alt='youtube' />
                </a>
                <a href='https://www.facebook.com/' target='_blank' rel='noreferrer'>
                  <img src={Facebook} alt='facebook' />
                </a>
                <a href='https://naver.com' target='_blank' rel='noreferrer'>
                  <img src={Playstore} alt='Playstore' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
