import { useEffect, useState } from 'react'

import { getBusiness, GetBusinessItem } from 'apis/business.ts'

export default function Business() {
  const [businessItemList, setBusinessItemList] = useState([])
  const [businessList, setBusinessList] = useState([])

  const headerHeight = 70

  const handleScroll = (name) => {
    const element = document.getElementById(name)
    const y = element.getBoundingClientRect().top + window.scrollY - headerHeight
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  //(01) 비즈니스 페이지 전체 정보
  const getBusinessApi = async () => {
    const getBusinessResponse = await getBusiness()
    if (getBusinessResponse.error) {
      console.log(getBusinessResponse.message)
      return
    }
    // console.log(getBusinessResponse.data)
    setBusinessList(getBusinessResponse.data)
  }

  // (02) 비즈니스 페이지 아이템 정보
  const getBusinessItemApi = async () => {
    const getBusinessItemResponse = await GetBusinessItem()

    if (getBusinessItemResponse.error) {
      console.log(getBusinessItemResponse.message)
      return
    }

    // console.log(getBusinessItemResponse)
    setBusinessItemList(getBusinessItemResponse.data)
  }

  useEffect(() => {
    getBusinessApi()
    getBusinessItemApi()
  }, [])

  // 백엔드 응답구조 때문에 item mapping 할 형태 새로 생성함
  let itemsList = []
  for (let businessItem of businessItemList) {
    itemsList.push([])
    for (let business of businessList) {
      if (businessItem.name === business.name) {
        itemsList[businessItem.itemOrder - 1].push(business)
      }
    }
  }

  return (
    <div className='px-[10px] wrapper'>
      <div className='flex flex-col'>
        <div className='container mx-auto'>
          {/* 비즈니스 */}
          <div className='py-20'>
            <div className='flex mb-[40px]'>
              <p className='text-body2-medium text-sk-sub'>홈 {'>'}&nbsp;</p>
              <p className='text-body2-semibold text-sk-default'>비즈니스</p>
            </div>

            <div className='flex flex-col items-center justify-center'>
              <div className='flex justify-center w-full'>
                <p className='text-display2-bold text-sk-default mb-[30px]'>비즈니스</p>
              </div>
              <div className='w-full flex justify-center gap-30 sm:gap-[60px] border-b-[2px] border-b-sk-primary py-[10px]'>
                {businessItemList.map((businessItem) => (
                  <div key={businessItem.idx} className='flex justify-center gap-30 sm:gap-[60px]'>
                    <p
                      className='select-none text-h1-semibold text-sk-sub hover:text-sk-primary hover:cursor-pointer'
                      onClick={() => handleScroll(businessItem.name)}
                    >
                      {businessItem.name}
                    </p>
                    {businessItemList.length === businessItem.idx}
                    <p
                      className={`h-[20px] border border-sk-border hover:text-sk-primary hover:cursor-pointer
                      ${businessItemList.length === businessItem.itemOrder ? 'hidden' : 'block'}
                      `}
                    ></p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {itemsList.map((items) => (
            <div key={items[0]?.idx} id={items[0]?.name} className='py-80'>
              {/* 비즈니스 아이템 이름 */}
              <div className='flex flex-col items-center'>
                <p className='text-title2-bold sm:text-title1-bold text-sk-default mb-[60px]'>
                  {items[0]?.name}
                </p>
              </div>

              {/* 비스니스 세부 아이템 */}
              <div className='flex flex-col gap-[30px]'>
                {items.map((item, index) =>
                  index === 0 ? (
                    <div key={index} className='flex flex-col h-full lg:flex-row'>
                      <div
                        style={{
                          backgroundImage: 'url(' + item.imgUrl + ')',
                        }}
                        className='bg-center bg-cover lg:basis-1/2'
                      ></div>
                      <div className='lg:basis-1/2 bg-sk-background-alternative pl-[60px] py-[40px] lg:py-[80px]'>
                        <div className='mb-32'>
                          <p className='mb-8 text-title3-bold text-sk-default'>{item.title}</p>
                          <p className='text-h2-medium text-sk-info'>{item.subTitle}</p>
                        </div>
                        <div className='flex flex-col gap-8 text-sk-sub text-body1-medium'>
                          {item.content.split('|').map((contentItem, index) => (
                            <li key={index}>{contentItem}</li>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div key={index} className='flex flex-col h-full lg:flex-row'>
                      <div className='lg:basis-1/2 bg-sk-background-alternative pl-[60px] py-[40px] lg:py-[80px]'>
                        <div className='mb-32'>
                          <p className='mb-8 text-title3-bold text-sk-default'>{item.title}</p>
                          <p className='text-h2-medium text-sk-info'>{item.subTitle}</p>
                        </div>
                        <div className='flex flex-col gap-8 text-sk-sub text-body1-medium'>
                          {item.content.split('|').map((contentItem, index) => (
                            <li key={index}>{contentItem}</li>
                          ))}
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundImage: 'url(' + item.imgUrl + ')',
                        }}
                        className='bg-center bg-cover lg:basis-1/2'
                      ></div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
