import { CommonResponse, get } from './common.ts'

/**
 * (01)
 * 메인페이지 배경 사진 정보
 */

// GET /main-page/background
interface GetMainPageBackgroundResponse {
  title: string
  subTitle: string
  text: string
  imgUrl: string
  idx: number
}

export const getMainPageBackground = async (): Promise<
  CommonResponse<GetMainPageBackgroundResponse>
> => {
  return await get('/main-page/background')
}

/**
 * (02)
 * 메인페이지 배너 사진 리스트 정보
 */

// GET /main-page/banner
interface GetMainPageBannerResponse {
  idx: number
  imgUrl: string
}

export const getMainPageBanner = async (): Promise<CommonResponse<GetMainPageBannerResponse>> => {
  return await get('/main-page/banner')
}

/**
 * (03)
 * 메인페이지 아이템 정보
 */

// GET /main-page/item
interface GetMainPageItemResponse {
  idx: number
  type: number
  title: string
  subTitle: string
  logoUrl: string
}

export const getMainPageItem = async (): Promise<CommonResponse<GetMainPageItemResponse>> => {
  return await get('/main-page/item')
}
