import Kakao from 'assets/logos/kakao.svg'
import Dropdown from 'assets/logos/arrow_drop_down.svg'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { postInquiry } from 'apis/inquiry.ts'
import { useSetRecoilState } from 'recoil'
import loadingState from 'states/loadingState'

// api
import { getLink } from 'apis/link.ts'

export default function Contact() {
  // [ react-hook-form ]
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      service: '',
      email3: 'defaultValue',
    },
  })

  // 제출(문의하기) 버튼 색상 활성화
  const [isActive, setIsActive] = useState(false)
  const watchAll = Object.values(watch())

  useEffect(() => {
    if (watchAll.every((el) => el)) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [watchAll])

  // email2, email3 항목 동기화
  const watchEmail3 = watch('email3')

  const isFirstRender = useRef(true)
  useEffect(() => {
    setValue('email2', watchEmail3 === 'defaultValue' ? '' : watchEmail3)
    if (!isFirstRender.current) {
      trigger('email2')
    } else {
      isFirstRender.current = false
    }
  }, [watchEmail3, setValue, trigger])

  // [ scroll event ]
  const headerHeight = 70
  const scroll1Ref = useRef(null)
  const scroll2Ref = useRef(null)

  const handleScroll1 = () => {
    const y = scroll1Ref.current.getBoundingClientRect().top + window.scrollY - headerHeight
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  const handleScroll2 = () => {
    const y = scroll2Ref.current.getBoundingClientRect().top + window.scrollY - headerHeight
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  // Loader recoil state
  const setLoading = useSetRecoilState(loadingState)

  // submit, API 연결
  const onSubmit = async (data) => {
    setLoading({ isLoading: true, message: '' })

    const postInquiryResponse = await postInquiry({
      service: data.service,
      name: data.name,
      company: data.company,
      tel: data.phoneNum1 + '-' + data.phoneNum2 + '-' + data.phoneNum3,
      email: data.email1 + '@' + data.email2,
      question: data.textarea,
    })

    setLoading({ isLoading: false, message: '' })
    console.log(postInquiryResponse)
    if (postInquiryResponse.error) {
      alert(postInquiryResponse.message)
      return
    }
    alert('접수되었습니다. 감사합니다.')
    reset()
  }

  async function getLinkApi() {
    const getLinkResponse = await getLink()

    if (getLinkResponse.error) {
      console.log(getLinkResponse.message)
      return
    }

    // console.log(getLinkResponse)
  }

  useEffect(() => {
    getLinkApi()
  }, [])

  return (
    <div className='px-[10px] wrapper'>
      <div className='container mx-auto'>
        {/* 문의하기 */}
        <div className='py-20'>
          <div className='flex mb-[40px]'>
            <p className='text-body2-medium text-sk-sub'>홈 {'>'}&nbsp;</p>
            <p className='text-body2-semibold text-sk-default'>문의하기</p>
          </div>

          <div className='flex flex-col items-center justify-center'>
            <div className='flex justify-center w-full'>
              <p className='text-display2-bold text-sk-default mb-[30px]'>문의하기</p>
            </div>
            <div className='w-full flex justify-center gap-30 sm:gap-[60px] border-b-[2px] border-b-sk-primary py-[10px]'>
              <p
                className='select-none text-h1-semibold text-sk-sub hover:text-sk-primary hover:cursor-pointer'
                onClick={handleScroll1}
              >
                카카오톡 상담하기
              </p>
              <p className='h-[20px] border border-sk-border'></p>
              <p
                className='select-none text-h1-semibold text-sk-sub hover:text-sk-primary hover:cursor-pointer'
                onClick={handleScroll2}
              >
                온라인 문의
              </p>
            </div>
          </div>
        </div>

        {/* 카카오톡 상담하기 */}
        <div ref={scroll1Ref} className='flex flex-col items-center gap-64 py-80'>
          <p className='mb-20 text-sk-default text-title2-bold sm:text-title1-bold'>
            카카오톡 상담하기
          </p>
          <div className='flex flex-col items-center'>
            <a
              href='https://www.kakaocorp.com/page/'
              target='_blank'
              rel='noreferrer'
              className='bg-[#FEE500] pl-20 pr-[50px] py-[9px] rounded-[10px] flex items-center gap-[50px]'
            >
              <img src={Kakao} alt='' />
              <p className='text-sk-default text-body1-semibold'>카카오톡 상담하기</p>
            </a>
            <p className='mt-20 text-sk-info text-caption-regular'>
              클릭 시 카카오톡 상담채널로 이동합니다.
            </p>
          </div>
        </div>

        {/* 온라인 문의 */}
        <div ref={scroll2Ref} className='flex flex-col items-center justify-center gap-40 py-80'>
          <p className='text-sk-default text-title2-bold sm:text-title1-bold'>온라인 문의</p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className='flex flex-col items-center justify-center gap-40 '
          >
            <div className='flex-col w-full'>
              {/* 서비스 희망 항목 */}
              <div className='flex flex-col border-t sm:flex-row border-sk-border'>
                <label
                  htmlFor='service'
                  className='select-none min-w-[205px] pl-20 pt-[25px] pb-[24px] pr-[65px] bg-sk-background-alternative text-h2-medium text-sk-default'
                >
                  서비스 희망 항목
                </label>

                <div className='flex items-center px-2 py-10 sm:px-20 grow'>
                  <select
                    {...register('service')}
                    className='placeholder:text-sk-disable w-full max-w-[500px] border pl-[14px] pr-[20px] pt-[15px] py-[16px] border-sk-border-sub bg-no-repeat bg-[center_right_20px]'
                    style={{ backgroundImage: 'url(' + Dropdown + ')' }}
                  >
                    <option value='' disabled className='text-sk-disable'>
                      서비스 희망 항목을 선택해주세요
                    </option>
                    <option value='service1'>서비스 1</option>
                    <option value='service2'>서비스 2</option>
                    <option value='service3'>서비스 3</option>
                  </select>
                </div>
              </div>

              {/* 성명 */}
              <div className='flex flex-col border-t sm:flex-row border-sk-border'>
                <label
                  htmlFor='name'
                  className='select-none min-w-[205px] pl-20 pt-[25px] pb-[24px] pr-[65px] bg-sk-background-alternative text-h2-medium text-sk-default'
                >
                  성명
                </label>

                <div className='flex flex-col gap-[10px] px-2 py-10 sm:px-20 grow'>
                  <input
                    type='text'
                    {...register('name', { required: '*성함은 필수 입력사항입니다.' })}
                    placeholder='성명을 입력해주세요'
                    className={`${
                      errors.name ? 'border-[#D50136]' : 'border-sk-border-sub'
                    } w-full max-w-[500px] border pl-[14px] pr-[20px] pt-[15px] py-[16px]`}
                  />
                  <div className={`${errors.name ? 'flex' : 'hidden'} items-end h-full`}>
                    <p className='text-body3-medium text-[#D50136]'>{errors.name?.message}</p>
                  </div>
                </div>
              </div>

              {/* 회사 */}
              <div className='flex flex-col border-t sm:flex-row border-sk-border'>
                <label
                  htmlFor='company'
                  className='select-none min-w-[205px] pl-20 pt-[25px] pb-[24px] pr-[65px] bg-sk-background-alternative text-h2-medium text-sk-default'
                >
                  회사
                </label>

                <div className='flex flex-col gap-[10px] px-2 py-10 sm:px-20 grow'>
                  <input
                    type='text'
                    {...register('company', { required: '*회사는 필수 입력사항입니다.' })}
                    placeholder='회사를 입력해주세요'
                    className={`${
                      errors.company ? 'border-[#D50136]' : 'border-sk-border-sub'
                    } w-full max-w-[500px] border pl-[14px] pr-[20px] pt-[15px] py-[16px]`}
                  />
                  <div className={`${errors.company ? 'flex' : 'hidden'} items-end h-full`}>
                    <p className='text-body3-medium text-[#D50136]'>{errors.company?.message}</p>
                  </div>
                </div>
              </div>

              {/* 연락처 */}
              <div className='flex flex-col border-t sm:flex-row border-sk-border'>
                <label
                  htmlFor='tel'
                  className='select-none min-w-[205px] pl-20 pt-[25px] pb-[24px] pr-[65px] bg-sk-background-alternative text-h2-medium text-sk-default'
                >
                  연락처
                </label>
                <div className='flex flex-col gap-[10px] px-2 py-10 sm:px-20 grow'>
                  <div className='flex items-center gap-20'>
                    <select
                      {...register('phoneNum1')}
                      className='*:placeholder:text-sk-disable w-full max-w-[500px] border pl-[14px] pr-[20px] pt-[15px] py-[16px] border-sk-border-sub bg-no-repeat bg-[center_right_20px]'
                      style={{ backgroundImage: 'url(' + Dropdown + ')' }}
                    >
                      <option value='02'>02</option>
                      <option value='031'>031</option>
                      <option value='010'>010</option>
                      <option value='010'>011</option>
                    </select>
                    <p className='text-sk-sub text-body2-medium'>-</p>
                    <input
                      type='tel'
                      {...register('phoneNum2', { required: '*연락처는 필수 입력사항입니다.' })}
                      className={`${
                        errors.phoneNum2 ? 'border-[#D50136]' : 'border-sk-border-sub'
                      } w-full max-w-[500px] border pl-[14px] pr-[20px] pt-[15px] py-[16px]`}
                    />
                    <p className='text-sk-sub text-body2-medium'>-</p>
                    <input
                      type='tel'
                      {...register('phoneNum3', { required: '*연락처는 필수 입력사항입니다.' })}
                      className={`${
                        errors.phoneNum3 ? 'border-[#D50136]' : 'border-sk-border-sub'
                      } w-full max-w-[500px] border pl-[14px] pr-[20px] pt-[15px] py-[16px]`}
                    />
                  </div>
                  <div
                    className={`${
                      errors.phoneNum2 || errors.phoneNum3 ? 'flex' : 'hidden'
                    } items-end h-full`}
                  >
                    <p className='text-body3-medium text-[#D50136]'>
                      *연락처는 필수 입력사항입니다.
                    </p>
                  </div>
                </div>
              </div>

              {/* 이메일 */}
              <div className='flex flex-col border-t sm:flex-row border-sk-border'>
                <label
                  htmlFor='email'
                  className='select-none min-w-[116px] sm:min-w-[205px] pl-20 pt-[25px] pb-[24px] pr-[20px] sm:pr-[65px] bg-sk-background-alternative text-h2-medium text-sk-default'
                >
                  이메일
                </label>
                <div className='flex flex-col gap-[10px] px-2 py-10 sm:px-20 grow'>
                  <div className='flex items-center gap-12 grow'>
                    <input
                      type='text'
                      {...register('email1', { required: '*이메일은 필수 입력사항입니다.' })}
                      className={`${
                        errors.email1 ? 'border-[#D50136]' : 'border-sk-border-sub'
                      } w-full max-w-[500px] border pl-[14px] pr-[20px] pt-[15px] py-[16px]`}
                    />
                    <p className='text-sk-sub text-body2-medium'>@</p>
                    <input
                      type='text'
                      {...register('email2', { required: '*이메일은 필수 입력사항입니다.' })}
                      className={`${
                        errors.email2 ? 'border-[#D50136]' : 'border-sk-border-sub'
                      } w-full max-w-[500px] border pl-[14px] pr-[20px] pt-[15px] py-[16px]`}
                    />
                    <select
                      {...register('email3')}
                      className='*:placeholder:text-sk-disable w-full max-w-[500px] border pl-[14px] pr-[20px] pt-[15px] py-[16px] border-sk-border-sub bg-no-repeat bg-[center_right_20px]'
                      style={{ backgroundImage: 'url(' + Dropdown + ')' }}
                    >
                      <option value='defaultValue' className='text-sk-disable'>
                        직접입력
                      </option>
                      <option value='naver.com'>naver.com</option>
                      <option value='google.com'>google.com</option>
                      <option value='outlook.com'>outlook.com</option>
                    </select>
                  </div>
                  <div
                    className={`${
                      errors.email1 || errors.email2 ? 'flex' : 'hidden'
                    } items-end h-full`}
                  >
                    <p className='text-body3-medium text-[#D50136]'>
                      *이메일은 필수 입력사항입니다.
                    </p>
                  </div>
                </div>
              </div>

              {/* 문의 내용 */}
              <div className='flex flex-col sm:flex-row border-y border-sk-border'>
                <label
                  htmlFor='question'
                  className='select-none sm:min-w-[205px] pl-20 pt-[25px] pb-[24px] sm:pb-[171px] pr-[20px] sm:pr-[65px] bg-sk-background-alternative text-h2-medium text-sk-default'
                >
                  문의 내용
                </label>

                <div className='flex flex-col px-2 py-10 sm:px-20 grow gap-[10px]'>
                  <textarea
                    type='textarea'
                    {...register('textarea', { required: true })}
                    placeholder='문의 내용을 입력해주세요'
                    className='resize-none text-start w-full h-full border pl-[14px] pr-[20px] pt-[15px] py-[16px] border-sk-border-sub bg-no-repeat bg-[center_right_20px]'
                  />
                  <div className={`${errors.textarea ? 'flex' : 'hidden'}`}>
                    <p className='text-body3-medium text-[#D50136]'>
                      *문의 내용은 필수 입력사항입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='bg-sk-background-alternative border-y border-sk-border pt-[19px] py-20 w-full flex justify-center items-center'>
              <div className='flex flex-col items-center sm:flex-row'>
                <div className='flex'>
                  <input
                    {...register('checkbox', {
                      required: '*개인정보 활용 동의는 필수 입력사항입니다.',
                    })}
                    type='checkbox'
                    id='checkbox'
                    className='mr-8 rounded-[4px] border border-sk-info'
                  />
                  <label htmlFor='checkbox' className='text-h2-medium text-sk-sub'>
                    상담문의를 위한 개인정보 활용을 동의합니다. &nbsp;
                  </label>
                </div>
                <div className={`${errors.checkbox ? 'flex' : 'hidden'} items-end h-full`}>
                  <p className='text-body3-medium text-[#D50136]'>
                    *개인정보 활용 동의는 필수 입력사항입니다.
                  </p>
                </div>
                {/* <p className='underline text-body1-semibold text-sk-sub'>자세히보기</p> */}
              </div>
            </div>

            <div className='flex gap-16'>
              <div
                className='px-30 sm:px-[70px] py-20 border border-sk-primary-disabled text-h1-semibold text-sk-primary-disabled hover:text-sk-primary select-none cursor-pointer'
                onClick={() => {
                  reset()
                }}
              >
                초기화
              </div>
              <input
                type='submit'
                value='문의하기'
                className={`px-30 sm:px-[70px] py-20 text-h1-semibold text-sk-white cursor-pointer select-none ${
                  isActive ? 'bg-sk-primary' : 'bg-sk-primary-disabled'
                }`}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
