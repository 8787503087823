import { CommonResponse, get } from './common.ts'

// GET /portfolio/item
interface GetPortfolioResponse {
  idx: number
  title: string
  description: string
  imgUrl: string
}

export const getPortfolio = async (): Promise<CommonResponse<GetPortfolioResponse>> => {
  return await get('/portfolio/item')
}
