import { Link } from 'react-router-dom'
import { useState } from 'react'
import { motion } from 'framer-motion'
import IconHamburger from 'assets/logos/icon_hamburger.svg'
import SideBar from './SideBar'

export default function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const toggleSide = () => {
    setIsOpen(true)
  }

  const navigationList = [
    { name: '회사소개', path: '/about' },
    { name: '비즈니스', path: '/business' },
    { name: '포트폴리오', path: '/portfolio' },
    { name: '문의하기', path: '/contact' },
  ]
  return (
    <div className='bg-sk-invert shadow-default h-[70px] w-full fixed z-50'>
      <div className='container flex items-center justify-between h-full px-20 mx-auto'>
        <Link to='/' className='w-[105px]'>
          <img src={require('assets/logos/logo-header.png')} alt='에듀케이션랩' />
        </Link>
        <div className='hidden md:flex h-full gap-[50px]'>
          {navigationList.map((nav, index) => (
            <Link
              key={index}
              to={nav.path}
              className='flex justify-center h-full px-[10px] text-h1-semibold text-sk-default hover:text-sk-primary hover:border-b-2 hover:border-sk-primary transition-all duration-300 ease-in-out'
            >
              <p className='mt-20 select-none'>{nav.name}</p>
            </Link>
          ))}
        </div>
        <div className='md:hidden'>
          <img
            src={IconHamburger}
            alt=''
            onClick={toggleSide}
            className='w-24 h-24 cursor-pointer'
          />
        </div>
        {isOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ ease: 'easeOut' }}
            className='fixed top-0 right-0 w-full h-full'
          >
            <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />
          </motion.div>
        )}
      </div>
    </div>
  )
}
