export default function NotFound() {
  return (
    <div className='wrapper'>
      <div className='container mx-auto py-80'>
        <div className='flex flex-col items-center justify-center gap-48'>
          <p className='text-title2-bold text-sk-default'>페이지를 찾을 수 없습니다</p>
          <div>
            <img src={require('assets/images/error.png')} alt='' />
          </div>
          <div className='text-center'>
            <p className='text-h1-semibold text-sk-info'>죄송합니다. 에러가 발생하였습니다.</p>
            <p className='text-h1-semibold text-sk-info'>
              잠시 후 다시 시도해주세요. 해당 현상이 반복되면 고객센터로 문의해주세요.
            </p>
          </div>
        </div>
        <div className='flex items-center justify-center py-80'>
          <div className='shrink-0 pr-[113.5px] pl-[114.5px] py-[20px] bg-[#9730FC]'>
            <p className='text-h1-semibold text-[#FFFFFF]'>메인으로 되돌아가기</p>
          </div>
        </div>
      </div>
    </div>
  )
}
