import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Home from 'views/Home'
import About from 'views/About'
import Business from 'views/Business'
import Portfolio from 'views/Portfolio'
import Contact from 'views/Contact'
import NotFound from 'views/NotFound'
import Header from 'components/Header'
import Footer from 'components/Footer'
import ScrollToTop from 'components/ScrollToTop'
import ScrollTopButton from 'components/ScrollTopButton'
import { RecoilRoot } from 'recoil'
import Loader from 'components/Loader'

function App() {
  return (
    <div className='App'>
      <RecoilRoot>
        <Router>
          <ScrollToTop />
          <Loader />
          <Header />
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/about/*' element={<About />}></Route>
            <Route path='/business/*' element={<Business />}></Route>
            <Route path='/portfolio/*' element={<Portfolio />}></Route>
            <Route path='/contact/*' element={<Contact />}></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Routes>
          <ScrollTopButton />
          <Footer />
        </Router>
      </RecoilRoot>
    </div>
  )
}

export default App
