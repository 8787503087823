import React from 'react'
import Close from 'assets/logos/close.svg'
import { useNavigate } from 'react-router-dom'

function SideBar({ isOpen, setIsOpen }) {
  const navigate = useNavigate()

  const toggleSide = () => {
    setIsOpen(false)
  }

  const goToHome = () => {
    setIsOpen(false)
    navigate('/')
  }

  const goToAbout = () => {
    setIsOpen(false)
    navigate('/about')
  }

  const goToBusiness = () => {
    setIsOpen(false)
    navigate('/business')
  }

  const goToPortfolio = () => {
    setIsOpen(false)
    navigate('/portfolio')
  }

  const goToContact = () => {
    setIsOpen(false)
    navigate('/contact')
  }

  return (
    <div className='fixed top-0 right-0 w-full h-full bg-[#FFF] flex flex-col px-20 py-[20px]'>
      <div className='flex justify-end mb-[47px]'>
        <img src={Close} alt='' onClick={toggleSide} className='w-24 h-24 cursor-pointer' />
      </div>
      <div className='flex flex-col items-center justify-center gap-[54px]'>
        <img
          src={require('assets/logos/logo-header.png')}
          alt=''
          className='h-32 w-80'
          onClick={goToHome}
        />
        <div className='flex flex-col gap-[50px] text-h1-semibold text-sk-default  justify-center items-center'>
          <p className='cursor-pointer select-none hover:text-sk-primary' onClick={goToAbout}>
            회사소개
          </p>
          <p className='cursor-pointer select-none hover:text-sk-primary' onClick={goToBusiness}>
            비즈니스
          </p>
          <p className='cursor-pointer select-none hover:text-sk-primary' onClick={goToPortfolio}>
            포트폴리오
          </p>
          <p className='cursor-pointer select-none hover:text-sk-primary' onClick={goToContact}>
            문의하기
          </p>
        </div>
      </div>
    </div>
  )
}

export default SideBar
