import { useEffect, useRef, useState } from 'react'

import HistoryCircle from 'assets/images/history-circle.svg'

// api
import { getCompanyHistory, getCompanyOutline } from 'apis/company.ts'

// const historyYearList = [
//   {
//     year: '2020.07',
//     history: ['주식회사 이랩 설립'],
//   },
//   {
//     year: '2021',
//     history: [
//       '심리케어 키오스크 개발',
//       'AI센터 외 그린스마트스쿨 구축',
//       '등 미래교육사업 확장',
//       '대한민국 자랑스러운',
//       '글로벌혁신기업 공헌대상 수상',
//       '교육국제화특구',
//       '에듀테크 영어교육사업',
//     ],
//   },
//   {
//     year: '2022',
//     history: [
//       'Online 수업시스템',
//       '충남, 경북 지역 교육기관 및',
//       '100개 학교 구축',
//       '특성화고등학교 학과재구조화 컨설팅',
//       '메타버스 교육플랫폼 교육기관',
//       '외 80개 학교 구축',
//     ],
//   },
//   {
//     year: '2023',
//     history: [
//       'On-Off line 교육행사 운영,',
//       '메타버스와 디지털 학습을',
//       '결합한 혁신적인 교육 솔류션',
//       '개발 및 컨설팅',
//     ],
//   },
//   {
//     year: '2024',
//     history: [
//       'On-Off line 교육행사 운영,',
//       '메타버스와 디지털 학습을',
//       '결합한 혁신적인 교육 솔류션',
//       '개발 및 컨설팅',
//     ],
//   },
// ]

export default function About() {
  const [companyOutlineList, setCompanyOutlineList] = useState([])
  const [historyYearList, setHistoryYearList] = useState([])

  const headerHeight = 70
  const scroll1Ref = useRef(null)
  const scroll2Ref = useRef(null)
  const scroll3Ref = useRef(null)

  const handleScroll1 = () => {
    const y = scroll1Ref.current.getBoundingClientRect().top + window.scrollY - headerHeight
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  const handleScroll2 = () => {
    const y = scroll2Ref.current.getBoundingClientRect().top + window.scrollY - headerHeight
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  const handleScroll3 = () => {
    const y = scroll3Ref.current.getBoundingClientRect().top + window.scrollY - headerHeight
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  // (01) 회사 소개 페이지 회사 개요 정보
  const getCompanyOutlineApi = async () => {
    const getCompanyOutlineResponse = await getCompanyOutline()

    if (getCompanyOutlineResponse.error) {
      console.log(getCompanyOutlineResponse)
      return
    }
    // console.log(getCompanyOutlineResponse)
    setCompanyOutlineList(getCompanyOutlineResponse.data)
  }

  // (02) 회사 소개 페이지 회사 연혁 정보
  const getCompanyHistoryApi = async () => {
    const getCompanyHistoryResponse = await getCompanyHistory()

    if (getCompanyHistoryResponse.error) {
      console.log(getCompanyHistoryResponse)
      return
    }
    // console.log(getCompanyHistoryResponse.data)
    setHistoryYearList(getCompanyHistoryResponse.data)
  }

  useEffect(() => {
    getCompanyOutlineApi()
    getCompanyHistoryApi()

    // eslint-disable-next-line
  }, [])

  return (
    <div className='wrapper '>
      <div className='container mx-auto px-[10px]'>
        {/* 회사소개 */}
        <div className='py-20'>
          <div className='flex mb-[40px]'>
            <p className='text-body2-medium text-sk-sub'>홈 {'>'}&nbsp;</p>
            <p className='text-body2-semibold text-sk-default'>회사소개</p>
          </div>

          <div className='flex flex-col items-center justify-center'>
            <div className='flex justify-center w-full'>
              <p className='text-display2-bold text-sk-default mb-[30px]'>회사소개</p>
            </div>
            <div className='w-full flex justify-center gap-30 sm:gap-[60px] border-b-[2px] border-b-sk-primary py-[10px]'>
              <p
                className='select-none text-h1-semibold text-sk-sub hover:text-sk-primary hover:cursor-pointer'
                onClick={handleScroll1}
              >
                개요
              </p>
              <p className='h-[20px] border border-sk-border'></p>
              <p
                className='select-none text-h1-semibold text-sk-sub hover:text-sk-primary hover:cursor-pointer'
                onClick={handleScroll2}
              >
                연혁
              </p>
              <p className='h-[20px] border border-sk-border'></p>
              <p
                className='select-none text-h1-semibold text-sk-sub hover:text-sk-primary hover:cursor-pointer'
                onClick={handleScroll3}
              >
                CEO 소개
              </p>
            </div>
          </div>
        </div>

        {/* 회사개요 */}
        <div ref={scroll1Ref} className='py-80'>
          <div className='flex flex-col items-center gap-40'>
            <p className='text-title2-bold sm:text-title1-bold text-sk-default'>회사 개요</p>

            <div className='flex flex-col gap-40 mb-32 lg:flex-row lg:gap-80'>
              <p className='text-sk-sub text-h1-semibold sm:text-title3-bold'>
                IT 교육의
                <br />
                새로운 감동의 역사를 쓰다
              </p>
              <p className='max-w-[587px] text-sk-info text-body1-medium'>
                4차 산업혁명 시대의 새로운 교육을 위해 학교 현장의 다양한 목적에 맞게 공간혁신,
                스마트교실, 학교복합화, AI ROBOTICS 등 데이터 플랫폼을 접목시켜 최상의 혼합현실 미래
                교육 서비스로 새로운 교육을 리딩합니다!
              </p>
            </div>

            <div className='grid grid-cols-1 gap-20 sm:grid-cols-2 lg:grid-cols-4'>
              {companyOutlineList.map((companyOutline, index) => (
                <div key={companyOutline.idx} className='relative'>
                  <img
                    src={require('assets/images/outline' + (index + 1) + '.png')}
                    alt={companyOutline.idx}
                    className='w-full'
                  />
                  <div className='absolute top-[30px] left-[20px] flex flex-col gap-16'>
                    <div className='text-title3-bold text-sk-default'>
                      <p>{companyOutline.title}</p>
                    </div>
                    <div className='text-body1-medium xl:text-h2-medium text-sk-default'>
                      {companyOutline.text.split('|').map((item, index) => (
                        <p key={index}>{item}</p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* 연혁 */}
      <div ref={scroll2Ref}>
        {/* pc view */}
        <div
          className='w-full bg-center bg-cover py-[55px] hidden lg:block'
          style={{
            backgroundImage: 'url(' + require('assets/images/history-background.png') + ')',
          }}
        >
          <div className='container flex flex-col items-center justify-center gap-40 mx-auto'>
            {/* 연혁 */}
            <div className='flex flex-col items-center gap-12'>
              <p className='text-sk-white text-title1-bold'>연혁</p>
              <p className='text-sk-assistive text-h1-medium'>
                기술 교육의 풍부한 색채로 미래를 그리다
              </p>
            </div>
            {/* content 개수 기본 5개, max 6개 */}
            <div className='relative flex gap-40'>
              <div className='absolute w-full h-[1px] border border-sk-border top-[90px] z-0'></div>
              {historyYearList.map((historyYearItem) => (
                <div key={historyYearItem.year} className='max-w-[240px] flex flex-col gap-[45px]'>
                  <div className='flex flex-col items-center gap-30'>
                    <p className='text-sk-invert text-title1-medium'>{historyYearItem.year}</p>
                    <div className='z-10'>
                      <img src={HistoryCircle} alt='' />
                    </div>
                  </div>
                  <div className='text-center text-sk-assistive text-h2-medium '>
                    {historyYearItem.content.split('|').map((historyItem, index) => (
                      <p key={index}>{historyItem}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* 모바일 view */}
        <div
          className='w-full bg-center bg-cover lg:hidden'
          style={{ backgroundImage: 'url(' + require('assets/images/history_mobile.png') + ')' }}
        >
          <div className='container flex flex-col items-center justify-center gap-40 mx-auto pt-[48px] py-[28px]'>
            {/* 연혁 */}
            <div className='flex flex-col items-center gap-12'>
              <p className='text-sk-white text-title1-bold'>연혁</p>
              <p className='text-sk-assistive text-h1-medium'>
                기술 교육의 풍부한 색채로 미래를 그리다
              </p>
            </div>
            {/* content */}
            <div className='relative flex flex-col gap-24'>
              <div className='absolute h-full w-[1px] border border-sk-border left-[12px] z-0'></div>
              {historyYearList.map((historyYearItem) => (
                <div
                  key={historyYearItem.year}
                  className='max-w-[350px] flex items-center justify-start gap-12 sm:gap-36'
                >
                  <div className='z-10 shrink-0'>
                    <img src={HistoryCircle} alt='' className='' />
                  </div>
                  <div className='flex flex-col items-center gap-8 grow'>
                    <p className='text-sk-invert text-title1-medium'>{historyYearItem.year}</p>
                    <div className='text-center text-sk-assistive text-h2-medium '>
                      {historyYearItem.content.split('|').map((historyItem, index) => (
                        <p key={index}>{historyItem}</p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* CEO 소개 */}

      <div ref={scroll3Ref} className='container mx-auto px-[10px]'>
        <div className='py-80'>
          <div className='flex flex-col items-center mb-40'>
            <p className='text-title3-bold sm:text-title1-bold text-sk-default'>CEO 소개</p>
          </div>

          <div className='flex gap-40'>
            <img
              src={require('assets/images/introduce.png')}
              alt=''
              className='hidden lg:block w-[420px] h-[593px]'
            />
            <div className='flex flex-col gap-[30px]'>
              <div>
                <p className='max-sm:text-center text-sk-default text-h1-semibold sm:text-title2-bold mb-[30px]'>
                  혁신적 교육의 빛나는 <br className='block sm:hidden' /> 비전을 디자인하다
                </p>
                <div className='flex flex-col gap-[30px] text-sk-default text-h2-regular sm:text-h1-regular'>
                  <p>
                    안녕하세요, <span className='font-bold'>㈜이랩</span>입니다.
                  </p>
                  <p>
                    ELAB은 '디지털 학습 혁명을 통해 세상을 설계하다'라는 슬로건 아래, 따뜻한 감성을
                    담은 IT 교육을 제공하는 메타버스 교육 회사입니다. 우리는 기술이 사람들에게 더
                    나은 미래를 선사할 수 있다고 믿으며, 그 중심에 교육이 있다고 생각합니다.
                  </p>
                  <p>
                    디지털 학습 환경을 통해 학생들이 꿈을 키우고, 창의력과 문제 해결 능력을 기를 수
                    있도록 돕고자 합니다. 또한, 우리가 제공하는 교육은 단순히 지식 전달을 넘어,
                    학습자 개개인이 자신의 잠재력을 발견하고 발휘할 수 있도록 하는 데 중점을 두고
                    있습니다.
                  </p>
                  <p>
                    우리의 목표는 누구나 쉽게 접근할 수 있는 교육 플랫폼을 통해 세상을 변화시키는
                    것입니다. 앞으로도 ELAB은 혁신적인 교육 솔루션을 지속적으로 개발하여, 많은
                    이들에게 더 나은 학습 기회를 제공할 수 있도록 노력하겠습니다.
                  </p>
                </div>
              </div>
              <div className='flex flex-col items-end gap-[14px]'>
                <p className='text-body1-medium text-sk-info'>(주)이랩 대표이사</p>
                <p className='text-title3-bold sm:text-title2-bold text-sk-default'>
                  황예지, 이혜선
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
