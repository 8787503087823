import React, { useState, useEffect } from 'react'
import LeftArrow from 'assets/logos/left_arrow.svg'
import RightArrow from 'assets/logos/right_arrow.svg'
import RoundFill from 'assets/logos/roundfill.svg'
import RoundNotFill from 'assets/logos/roundnotfill.svg'

export default function ImageSlider({ slides }) {
  const [current, setCurrent] = useState(0)
  const length = slides.length

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide()
    }, 5000)

    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [current, length])

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null
  }
  return (
    <div className='relative w-full overflow-hidden'>
      <div
        style={{ transform: `translateX(-${100 * current}%)` }}
        className='flex w-full transition-all duration-700 ease-in-out'
      >
        {slides.map((slide) => {
          return (
            <div
              style={{ backgroundImage: 'url(' + slide.imgUrl + ')' }}
              className='bg-no-repeat bg-cover bg-center w-full shrink-0 h-[200px] md:h-[600px]'
            />
          )
        })}
      </div>
      <div className='absolute flex bottom-[10%] left-[10%] items-center'>
        <div className='flex gap-20 mr-30 '>
          <img
            src={LeftArrow}
            alt=''
            className='w-[40px] h-[40px] hover:cursor-pointer'
            onClick={prevSlide}
          />
          <img
            src={RightArrow}
            alt=''
            className='w-[40px] h-[40px] hover:cursor-pointer'
            onClick={nextSlide}
          />
        </div>
        <div className='flex gap-6'>
          {slides.map((slide, index) => {
            const imageSrc = current === index ? RoundFill : RoundNotFill
            return <img src={imageSrc} alt=''></img>
          })}
        </div>
      </div>
    </div>
  )
}
