import { CommonResponse, get } from './common.ts'

// GET /link
interface GetLinkResponse {
  idx: number
  naverBlog: string
  appStore: string
  tistory: string
  youtube: string
  kakaoTalk: string
}

export const getLink = async (): Promise<CommonResponse<GetLinkResponse>> => {
  return await get('/link')
}
