import { useEffect, useState } from 'react'

import ImageSlider from 'components/ImageSlider'

// api
import { getMainPageBackground, getMainPageBanner, getMainPageItem } from 'apis/page.ts'

// const partnerCompanyList = [
//   {
//     hasText: true,
//     logoImgPath: require('assets/images/partner1.png'),
//     title: '메타버스 플랫폼',
//     description: `공교육기관 메타버스 플랫폼 1위 업체
// 경북교육청 54개 학교 메타버스 가상학교 구축`,
//   },
//   {
//     hasText: true,
//     logoImgPath: require('assets/images/partner5.png'),
//     title: 'AI 고객맞춤형 로봇',
//     description: `AI 바우처 선정
// 온라인 인공지능(AI) 교육 플랫폼을 개발`,
//   },
//   {
//     hasText: true,
//     logoImgPath: require('assets/images/partner4.png'),
//     title: '통합 공간 솔루션',
//     description: `충남교육청 진로융합교육원, 융합교육 혁신센터 등
// 가변형 공간 운영 시스템 구축`,
//   },
//   {
//     hasText: true,
//     logoImgPath: require('assets/images/partner6.png'),
//     title: '교육용 AI 로봇/플랫폼',
//     description: `임베디드 기반 초소형
// 인공지능 IoT 모듈 개발`,
//   },
//   {
//     hasText: true,
//     logoImgPath: require('assets/images/partner2.png'),
//     title: '디지털 교육 기자재의 선두주자',
//     description: `4차 산업혁명 미래교육
// 에듀테크 솔류션, 스마트 교실 구축`,
//   },
//   {
//     hasText: true,
//     logoImgPath: require('assets/images/partner3.png'),
//     title: '디지털 사이니지, 디스플레이',
//     description: `나라장터 영상정보디스플레이장치 6년 연속 매출 1위
// 전자칠판 판서 솔류션 GS인증 1등급 취득`,
//   },
//   {
//     hasText: true,
//     logoImgPath: require('assets/images/partner7.png'),
//     title: '국내 최초 철강 회사',
//     description: `현대제철 ‘철 그 이상의 가치 창조’
// 고로 사업, 전기로 사업, AP/강관사업`,
//   },
//   {
//     hasText: false,
//     logoImgPath: require('assets/images/partner8.png'),
//     title: '',
//     description: '',
//   },
//   {
//     hasText: false,
//     logoImgPath: require('assets/images/partner9.png'),
//     title: '',
//     description: '',
//   },
//   {
//     hasText: false,
//     logoImgPath: require('assets/images/partner10.png'),
//     title: '',
//     description: '',
//   },
//   {
//     hasText: false,
//     logoImgPath: require('assets/images/partner11.png'),
//     title: '',
//     description: '',
//   },
//   {
//     hasText: false,
//     logoImgPath: require('assets/images/partner12.png'),
//     title: '',
//     description: '',
//   },
// ]

// const SlideImageList = [
//   { id: 1, image: require('assets/images/slider-main-1.png') },
//   { id: 2, image: require('assets/images/slider-main-2.png') },
//   { id: 3, image: require('assets/images/slider-main-3.png') },
//   { id: 4, image: require('assets/images/slider-main-4.png') },
// ]

export default function Home() {
  const [mainPageBackground, setMainPageBackground] = useState({})
  const [SlideImageList, setSlideImageList] = useState([])
  const [partnerCompanyList, setPartnerCompanyList] = useState([])

  // (01) 메인페이지 배경 사진 정보
  const getMainPageBackgroundApi = async () => {
    const getMainPageBackgroundResponse = await getMainPageBackground()

    if (getMainPageBackgroundResponse.error) {
      console.log(getMainPageBackgroundResponse)
      return
    }
    // console.log(getMainPageBackgroundResponse)
    setMainPageBackground(getMainPageBackgroundResponse.data)
  }

  // (02) 메인페이지 배너 사진 리스트 정보
  const getMainPageBannerApi = async () => {
    const getMainPageBannerResponse = await getMainPageBanner()

    if (getMainPageBannerResponse.error) {
      console.log(getMainPageBannerResponse)
      return
    }
    // console.log(getMainPageBannerResponse)
    setSlideImageList(getMainPageBannerResponse.data)
  }

  // (03) 메인페이지 아이템 정보
  const getMainPageItemApi = async () => {
    const getMainPageItemResponse = await getMainPageItem()

    if (getMainPageItemResponse.error) {
      console.log(getMainPageItemResponse)
      return
    }
    // console.log(getMainPageItemResponse)
    setPartnerCompanyList(getMainPageItemResponse.data)
  }

  useEffect(() => {
    getMainPageBackgroundApi()
    getMainPageBannerApi()
    getMainPageItemApi()
  }, [])

  return (
    <div className='wrapper'>
      {/* section 1 */}
      <div className='relative sm:px-64 xl:px-[200px] py-40 px-[10px] '>
        {/* 배경 사진 */}
        <div className='max-h-[800px]'>
          <img src={mainPageBackground.imgUrl} alt='' className='w-full max-h-[800px]' />
        </div>
        <div className='w-full absolute flex flex-col items-center top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]'>
          {/* 제목 */}
          <p className='select-none text-sk-white font-["Poppins"] text-[24px] sm:text-[30px] md:text-[40px] lg:text-[56px] 2xl:text-[68px] font-semibold leading-[140px] tracking-[-2.5%]'>
            {mainPageBackground.title}
          </p>
          {/* 부제목 */}
          <p className='max-sm:hidden md:mt-4 2xl:mt-8 text-body1-semibold md:text-title3-bold lg:text-title2-bold 2xl:text-title1-bold text-sk-assistive'>
            {mainPageBackground.subTitle}
          </p>
          {/* 본문 */}
          <p className='mt-20 max-sm:hidden 2xl:mt-40 text-body1-medium md:text-h1-medium 2xl:text-title3-medium text-sk-assistive'>
            {mainPageBackground.text}
          </p>
        </div>
      </div>

      {/* section 2 */}
      <div className='container mx-auto px-[10px] '>
        <div className='flex flex-col items-center justify-center pb-64 pt-80'>
          <p className='mb-16 text-h1-medium text-sk-primary'>We Work</p>
          <p className='text-title3-bold sm:text-title1-bold text-sk-default mb-[12px]'>
            다차원 혼합현실 교육솔루션으로 새로운 교육을 리딩합니다!
          </p>
          <p className='text-center text-sk-info text-h2-regular'>
            기획부터 운영까지, 혼합현실 교육 All In One 솔루션 <br /> 학교 현장의 다양한 목적에 맞게
            데이터 플랫폼을 접목시켜 최상의 혼합현실 교육(Self training) 솔루션 제공
          </p>
        </div>
      </div>

      <div className='w-full mb-80'>
        <ImageSlider slides={SlideImageList} />
      </div>

      {/* section 3 */}
      <div className='container mx-auto px-[10px] '>
        <div className='flex flex-col items-center pb-80'>
          <div className='flex flex-col items-center mb-48'>
            <p className='mb-16 text-h1-medium text-sk-primary '>Partners</p>
            <p className='text-center text-title3-bold sm:text-title1-medium text-sk-default'>
              이랩은&nbsp;
              <span className='text-title3-bold sm:text-title1-bold text-sk-default'>
                공교육기관 및 일선학교,
                <br className='hidden sm:block' /> 다양한 사교육 기업체
              </span>
              와 함께하고 있습니다!
            </p>
          </div>

          <div className='w-full'>
            <div className='grid grid-cols-1 xl:grid-cols-2 gap-30'>
              {partnerCompanyList.map((partnerCompanyItem, index) => (
                <div
                  key={index}
                  className='max-sm:pb-40 flex flex-col sm:flex-row justify-between items-center sm:h-[180px] border border-sk-border rounded-[10px]'
                >
                  {partnerCompanyItem.type === 1 ? (
                    <>
                      <div className='w-[240px] max-sm:h-[160px] flex justify-center items-center'>
                        <img
                          src={partnerCompanyItem.imgUrl}
                          alt={partnerCompanyItem.title}
                          className='max-w-[160px]'
                        />
                      </div>
                      <div className='flex flex-col gap-12 pr-20 text-center grow'>
                        <p className='text-title3-bold xl:text-title2-bold text-sk-strong'>
                          {partnerCompanyItem.title}
                        </p>
                        <div>
                          {partnerCompanyItem.subTitle.split('|').map((item, index) => (
                            <p key={index} className='text-sk-sub text-body1-medium'>
                              {item}
                            </p>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='max-sm:pt-[60px] flex items-center justify-center py-20 px-[50px] md:px-[120px]'>
                      <img
                        src={partnerCompanyItem.imgUrl}
                        alt={partnerCompanyItem.idx}
                        className='h-full'
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
