import { useEffect, useRef, useState } from 'react'

import Logo_portfolio from 'assets/logos/logo_portfolio.svg'
import Blog from 'assets/logos/blog_portfolio.svg'
import Apple from 'assets/logos/apple_portfolio.svg'
import Tstory from 'assets/logos/tstory_portfolio.svg'
import Instagram from 'assets/logos/instagram_portfolio.svg'
import Youtube from 'assets/logos/youtube_portfolio.svg'
import Facebook from 'assets/logos/facebook_portfolio.svg'

// api
import { getPortfolio } from 'apis/portfolio.ts'
import { getLink } from 'apis/link.ts'

export default function Portfolio() {
  const [portfolios, setPortfolios] = useState([])
  const [links, setLinks] = useState({})

  const headerHeight = 70
  const scroll1Ref = useRef(null)
  const scroll2Ref = useRef(null)

  const handleScroll1 = () => {
    const y = scroll1Ref.current.getBoundingClientRect().top + window.scrollY - headerHeight
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  const handleScroll2 = () => {
    const y = scroll2Ref.current.getBoundingClientRect().top + window.scrollY - headerHeight
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  async function getPortfolioApi() {
    const getPortfolioResponse = await getPortfolio()

    if (getPortfolioResponse.error) {
      console.log(getPortfolioResponse.message)
      return
    }
    // console.log(getPortfolioResponse)
    setPortfolios(getPortfolioResponse.data)
  }

  async function getLinkApi() {
    const getLinkResponse = await getLink()

    if (getLinkResponse.error) {
      console.log(getLinkResponse.message)
      return
    }

    // console.log(getLinkResponse)
    setLinks(getLinkResponse.data)
  }

  useEffect(() => {
    getPortfolioApi()
    getLinkApi()
  }, [])

  return (
    <div className='px-[10px] wrapper'>
      <div className='container mx-auto'>
        {/* 포트폴리오 */}
        <div className='py-20'>
          <div className='flex mb-[40px]'>
            <p className='text-body2-medium text-sk-sub'>홈 {'>'}&nbsp;</p>
            <p className='text-body2-semibold text-sk-default'>포트폴리오</p>
          </div>

          <div className='flex flex-col items-center justify-center'>
            <div className='flex justify-center w-full'>
              <p className='text-display2-bold text-sk-default mb-[30px]'>포트폴리오</p>
            </div>
            <div className='w-full flex justify-center gap-30 sm:gap-[60px] border-b-[2px] border-b-sk-primary py-[10px]'>
              <p
                className='select-none text-h1-semibold text-sk-sub hover:text-sk-primary hover:cursor-pointer'
                onClick={handleScroll1}
              >
                포트폴리오
              </p>
              <p className='h-[20px] border border-sk-border'></p>
              <p
                className='select-none text-h1-semibold text-sk-sub hover:text-sk-primary hover:cursor-pointer'
                onClick={handleScroll2}
              >
                소셜채널
              </p>
            </div>
          </div>
        </div>

        {/* 포트폴리오 */}
        <div ref={scroll1Ref} className='py-80'>
          <div className='flex flex-col items-center justify-center mb-32'>
            <p className='mb-40 text-title2-bold sm:text-title1-bold text-sk-default'>포트폴리오</p>
            <div className='flex flex-col gap-40 xl:flex-row xl:gap-80'>
              <p className='text-h1-semibold sm:text-title3-bold text-sk-sub'>
                데이터로 채워진
                <br />
                학습의 따뜻한 길을 그리다
              </p>
              <p className='sm:text-h2-medium text-sk-info'>
                이랩은 공교육기관 및 일선학교의 메타버스와 혼합현실공간 그리고 각 종 기자재들을 구축
                및 기획했으며, <br className='hidden md:block' />
                수십 건의 메타버스 프로젝트를 안정적으로 운영하고 있습니다.
              </p>
            </div>
          </div>

          <div className='grid gap-10 sm:grid-cols-2 md:gap-20 md:grid-cols-3'>
            {portfolios.map((portfolio, index) => (
              <div
                className='flex flex-col border border-sk-border rounded-[10px] overflow-hidden'
                key={index}
              >
                <div
                  className='h-[280px] sm:h-[350px] bg-no-repeat bg-cover'
                  style={{
                    backgroundImage: `url(${portfolio.imgUrl})`,
                    backgroundPosition: '50% 20%',
                  }}
                ></div>
                <div className='px-20 py-[21px] bg-sk-background-alternative grow'>
                  <p className='text-body1-medium text-sk-primary'>{portfolio.title}</p>
                  <p className='text-h2-semibold sm:text-h1-semibold text-sk-default'>
                    {portfolio.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* 소셜채널 */}
        <div ref={scroll2Ref} className='flex flex-col items-center justify-center py-80'>
          <div className='text-center'>
            <p className='mb-8 text-title2-bold sm:text-title1-bold text-sk-default'>소셜채널</p>
            <p className='mb-40 text-h2-medium sm:text-h1-medium'>
              이랩의 소식을 빠르게 만나보세요
            </p>
          </div>
          <div className='flex flex-col w-full lg:flex-row'>
            <div className='flex bg-[#F0F0F0] h-[192px] rounded-t-[10px] lg:rounded-l-[10px]'>
              <div className='flex grow justify-center items-center mr-[23px]'>
                <p className='text-h2-semibold sm:text-title3-bold ml-[10px] sm:ml-[49px]'>
                  가상 학습의
                  <br className='block sm:hidden' /> 감동적인 시대를 열다
                </p>
              </div>
              <div className='flex items-end grow'>
                <img src={Logo_portfolio} alt='' className='max-w-[112px] max-h-[129px]' />
              </div>
            </div>
            <div className='grow flex flex-col sm:h-[192px] bg-[#A557D5] max-lg:rounded-b-[10px] lg:rounded-r-[10px]'>
              <div className='ml-[32px] mt-[30px] mb-[16px]'>
                <p className='text-body1-medium text-[#FFF]'>이랩 SNS 바로가기</p>
              </div>
              <div className='flex justify-around px-20 pb-20 sm:pb-30'>
                <a
                  href={links.naverBlog}
                  target='_blank'
                  rel='noreferrer'
                  className='flex flex-col items-center justify-center w-[77px]'
                >
                  <img src={Blog} alt='' className='' />
                  <p className='hidden sm:block text-body2-medium text-[#E1E3E4]'>네이버 블로그</p>
                </a>
                <a
                  href={links.appStore}
                  target='_blank'
                  rel='noreferrer'
                  className='flex flex-col items-center justify-center w-[77px]'
                >
                  <img src={Apple} alt='' className='' />
                  <p className='hidden sm:block text-body2-medium text-[#E1E3E4]'>App Store</p>
                </a>
                <a
                  href={links.tistory}
                  target='_blank'
                  rel='noreferrer'
                  className='flex flex-col items-center justify-center w-[77px]'
                >
                  <div className='h-[72px] flex items-center'>
                    <img src={Tstory} alt='' className='' />
                  </div>
                  <p className='hidden sm:block text-body2-medium text-[#E1E3E4]'>티스토리</p>
                </a>
                <a
                  href={links.instagram}
                  target='_blank'
                  rel='noreferrer'
                  className='flex flex-col items-center justify-center w-[77px]'
                >
                  <img src={Instagram} alt='' className='' />
                  <p className='hidden sm:block text-body2-medium text-[#E1E3E4]'>인스타그램</p>
                </a>
                <a
                  href={links.youtube}
                  target='_blank'
                  rel='noreferrer'
                  className='flex flex-col items-center justify-center w-[77px]'
                >
                  <img src={Youtube} alt='' className='' />
                  <p className='hidden sm:block text-body2-medium text-[#E1E3E4]'>유튜브</p>
                </a>
                <a
                  href={links.faceBook}
                  target='_blank'
                  rel='noreferrer'
                  className='flex flex-col items-center justify-center w-[77px]'
                >
                  <img src={Facebook} alt='' className='' />
                  <p className='hidden sm:block text-body2-medium text-[#E1E3E4]'>페이스북</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
