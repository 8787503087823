import { CommonResponse, post } from './common.ts'

// POST /inquiry
interface PostInquiryRequest {
  service: string
  name: string
  company: string
  tel: string
  email: string
  question: string
}

export const postInquiry = async (body: PostInquiryRequest): Promise<CommonResponse<null>> => {
  return await post('/inquiry/add', body)
}
