import axios, { AxiosResponse, isAxiosError } from 'axios'
const BASE_URL = 'https://server.educationlab.kr/v1'

export interface CommonResponse<T> {
  error: boolean
  message: string
  data: T
}

export const get = async (uri: string): Promise<CommonResponse<any>> => {
  let response: AxiosResponse<CommonResponse<any>>
  try {
    response = (await axios.get(BASE_URL + uri, {
      withCredentials: true,
      timeout: 5000,
    })) as AxiosResponse<CommonResponse<any>>
    return response.data
  } catch (e) {
    if (!isAxiosError<CommonResponse<any>>(e)) {
      return {
        error: true,
        message: '오류가 발생했습니다.',
        data: null,
      }
    }

    if (e.response?.status) {
      if (e.response.status === 521 || e.response.status === 502) {
        return {
          error: true,
          message: '서버 연결에 실패했습니다.',
          data: null,
        }
      }
    }

    if (e.response?.data) {
      return e.response.data
    }

    return {
      error: true,
      message: '서버 연결에 실패했습니다.',
      data: null,
    }
  }
}

export const post = async (uri: string, body: any): Promise<CommonResponse<any>> => {
  try {
    let response = (await axios.post(BASE_URL + uri, body, {
      withCredentials: true,
      timeout: 5000,
    })) as AxiosResponse<CommonResponse<any>>
    return response.data
  } catch (e) {
    if (!isAxiosError<CommonResponse<any>>(e)) {
      return {
        error: true,
        message: '오류가 발생했습니다.',
        data: null,
      }
    }

    if (e.response?.status) {
      if (e.response.status === 521 || e.response.status === 502) {
        return {
          error: true,
          message: '서버 연결에 실패했습니다.',
          data: null,
        }
      }
    }

    if (e.response?.data) {
      return e.response.data
    }

    return {
      error: true,
      message: '서버 연결에 실패했습니다.',
      data: null,
    }
  }
}
