import { CommonResponse, get } from './common.ts'

/**
 * (01)
 * 회사 소개 페이지 회사 개요 정보
 */

// GET /company/outline
interface GetCompanyOutlineResponse {
  idx: number
  title: string
  text: string
}

export const getCompanyOutline = async (): Promise<CommonResponse<GetCompanyOutlineResponse>> => {
  return await get('/company/outline')
}

/**
 * (02)
 * 회사 소개 페이지 회사 연혁 정보
 */

// GET /company/history
interface GetCompanyHistoryResponse {
  idx: number
  year: string
  content: string
}

export const getCompanyHistory = async (): Promise<CommonResponse<GetCompanyHistoryResponse>> => {
  return await get('/company/history')
}
