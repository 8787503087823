import React from 'react'
import IconTop from 'assets/logos/Icon_Top.svg'

export default function ScrollTopButton() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div className='div w-[90px] h-[90px] sticky bottom-[20px] left-[calc(100%-50px)]'>
      <img src={IconTop} alt='' className='cursor-pointer' onClick={scrollToTop} />
    </div>
  )
}
