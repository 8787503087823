import React from 'react'
import Lottie from 'lottie-react'
import * as loaderLottie from 'assets/lotties/loaderLottie.json'
import { useRecoilState } from 'recoil'
import loadingState from 'states/loadingState'

export default function Loader() {
  const [loading] = useRecoilState(loadingState)

  return (
    <>
      {loading.isLoading && (
        <div className='fixed w-full h-screen flex flex-col justify-center items-center bg-[#2a2c45c5] z-[999]'>
          <div>
            <Lottie animationData={loaderLottie} style={{ width: '300px', height: '300px' }} />
          </div>
        </div>
      )}
    </>
  )
}
