import { CommonResponse, get } from './common.ts'

/**
 * (01)
 * 비즈니스 페이지 전체 정보
 */

// GET /business
interface GetBusinessResponse {
  idx: number
  businessIdx: number
  name: string
  title: string
  subTitle: string
  content: string
  logoUrl: string
}

export const getBusiness = async (): Promise<CommonResponse<GetBusinessResponse>> => {
  return await get('/business')
}

/**
 * (02)
 * 비즈니스 페이지 아이템 정보
 */

// GET /business/item
interface GetBusinessItemResponse {
  idx: number
  name: string
}

export const GetBusinessItem = async (): Promise<CommonResponse<GetBusinessItemResponse>> => {
  return await get('/business/item')
}
